import { defineChannelAudienceAnalysisStore } from '@/stores/analysis/analyses/channel-audience'

import determineGranularity from '@/helpers/determine-granularity'
import number from '@/helpers/number'

import { differenceInHours, subDays, subHours, subMonths, subWeeks, subYears } from 'date-fns'
import { defineStore } from 'pinia'

export const defineMetricsStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        series: [],

        isInitialized: false,
        isLoading: false,

        enabledMetrics: [ 'followers', 'members' ],
        selectedMetric: 'followers',

        supportedMetrics: [
            { id: 'followers', name: 'Followers', icon: 'users', aggregate: 'followers:count', dataQuality: 'observed' },
            { id: 'members', name: 'Members', icon: 'message-circle', aggregate: 'members:count', dataQuality: 'observed' }
        ],

        analysisStore: defineChannelAudienceAnalysisStore({ id: `${settings.id}Analysis` })
    }),

    getters: {
        analysisOptions: store => ({
            chart: {
                backgroundColor: false,
                type: 'line',
                zoomType: 'xy',
                resetZoomButton: {
                    position: {
                        x: -40,
                        y: -5,
                    }
                },
                spacing: [0, 0, 0, 0]
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: '',
            },
            plotOptions: {
                series: {
                    lineWidth: 3,
                    marker: {
                        enabled: false
                        //                        symbol: 'circle',
                        //                        radius: 5
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [
                {
                    id: 'values',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent',
                    min: Math.min(...store.series.flatMap(series => store.activeMetric(series).data.map(p => p.y))) * 0.9,
                    max: Math.max(...store.series.flatMap(series => store.activeMetric(series).data.map(p => p.y))) * 1.1
                }
            ],
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () {
                    return `<strong>${this.series.name}: ${number(this.y)}</strong><br/>`
                },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' }
            },
            legend: {
                enabled: false
            },
            series: store.series.map(series => ({
                //                    color: '#9fc2f9',
                //                    color: '#9333ea',
                color: series.color,
                data: store.activeMetric(series).data,
                name: series.name,
                //                    type: 'column',
                type: 'spline',
                yAxis: 0
            })),
            credits: {
                enabled: false
            }
        }),

        isComparing: store => store.series.length > 1,
        isRenderable: store => store.analysisStore().isRenderable
    },

    actions: {
        async initialize(series) {
            this.series = [ { ...series, currentMetrics: {}, previousMetrics: {} } ]

            await this.load()

            this.isInitialized = true
        },

        async compareWith(series) {
            this.series.push({ ...series, currentMetrics: {}, previousMetrics: {} })

            this.isLoading = true

            await this.load()
        },

        stopComparing(series) {
            this.series = this.series.filter(s => s.id != series.id)

            this.load()
        },

        async load() {
            this.isLoading = true

            await Promise.all([
                this.loadAnalysis()
            ])
            
            this.isLoading = false
        },

        loadAnalysis() {
            let store = this.analysisStore()

            store.series = this.series.flatMap(series => [
                {
                    label: 'Followers',
                    color: '#000',
                    datasetFamily: 'channels',
                    datasetId: series.channel.id,
                    datasetName: series.channel.name,
                    meta: { metric: 'followers' }

                },
                {
                    label: 'Members',
                    color: '#000',
                    datasetFamily: 'channels',
                    datasetId: series.channel.id,
                    datasetName: series.channel.name,
                    meta: { metric: 'members' }
                }
            ])

            if (this.series[0].filters.value('date')) {
                store.date = this.series[0].filters.value('date')
                store.granularity = determineGranularity(store.date)
            } else {
                store.date = { type: 'past', date: { past: 1, unit: 'years' } }
                store.granularity = 'month'
            }

            return store.loadSeries()
        },

        previousDateRange() {
            let dateFilter = this.series[0].filters.value('date')

            if (! dateFilter) return

            if (dateFilter.type == 'past') {
                if (dateFilter.date.unit == 'days') {
                    return { type: 'in-range', date: { gte: subDays(new Date(), dateFilter.date.past * 2), lte: subDays(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'hours') {
                    return { type: 'in-range', date: { gte: subHours(new Date(), dateFilter.date.past * 2), lte: subHours(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'weeks') {
                    return { type: 'in-range', date: { gte: subWeeks(new Date(), dateFilter.date.past * 2), lte: subWeeks(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'months') {
                    return { type: 'in-range', date: { gte: subMonths(new Date(), dateFilter.date.past * 2), lte: subMonths(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'years') {
                    return { type: 'in-range', date: { gte: subYears(new Date(), dateFilter.date.past * 2), lte: subYears(new Date(), dateFilter.date.past) } }
                }
            } else if (dateFilter.type == 'in-range') {
                return { type: 'in-range', date: { gte: subHours(dateFilter.date.gte, differenceInHours(dateFilter.date.lte, dateFilter.date.gte)), lte: dateFilter.date.gte } }
            }
        },

        metrics(series) {
            let current, previous, values
            
            return this.supportedMetrics.map((metric, index) => ({
                ...metric,
                data: values = this.analysisStore().analysisSeries[this.series?.indexOf(series) * this.supportedMetrics.length + index]?.values,
                current: current = values?.[values?.length - 1]?.y,
                previous: previous = values?.[0]?.y,
                change: current && previous ? Math.round(Math.abs(current - previous) / current * 100) : null
            })).filter(metric => this.enabledMetrics.includes(metric.id))
        },

        activeMetric(series) {
            return this.metrics(series).find(metric => metric.id == this.selectedMetric)
        }
    }
})

export default defineMetricsStore
