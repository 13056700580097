export function sortAlphabetically(items, field = 'name') {
    let resolveValue = typeof field == 'string' ? (val => val[field] || '') : field
    return [ ...items ].sort((a, b) => resolveValue(a).localeCompare(resolveValue(b)))
}

export function sortByDate(items, field = 'createdAt') {
    let resolveValue = typeof field == 'string' ? (val => val[field] || '') : field
    return [ ...items ].sort((a, b) => new Date(resolveValue(a)) - new Date(resolveValue(b)))
}

export function sortNaturally(items, field = 'name') {
    let resolveValue = typeof field == 'string' ? (val => val[field] || '') : field
    items = [ ...items ].sort((a, b) => resolveValue(a).localeCompare(resolveValue(b)))

    let pinned = items.filter(item => item.pinned)
    let other = items.filter(item => ! item.pinned)

    let recent = other.filter(item => item.recent)
    other = other.filter(item => ! item.recent)

    return [ ...pinned, ...recent, ...other ]
}

export default sortNaturally
