import { defineContentPerformanceAnalysisStore } from '@/stores/analysis/analyses/content-performance'

import { content } from '@/api'
import determineGranularity from '@/helpers/determine-granularity'

import { differenceInHours, subDays, subHours, subMonths, subWeeks, subYears } from 'date-fns'
import { defineStore } from 'pinia'

export const defineOverviewMetricsStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        current: [],
        previous: [],

        isInitialized: false,
        isLoading: false,

        filters: null,
        analysisStores: settings.metrics.map((m, index) => ({
            current: defineContentPerformanceAnalysisStore({ id: `${settings.id}Analysis${index}` }),
            previous: defineContentPerformanceAnalysisStore({ id: `${settings.id}PreviousAnalysis${index}` })
        })),
        
        metrics: settings.metrics
    }),

    getters: {
        change: store => {
            return store.current.map((v, index) => v && store.previous[index] ? Math.round(Math.abs(v - store.previous[index]) / v * 100) : null)
        },

        chartOptions: store => store.metrics.map((m, index) => ({
            boost: { useGPUTranslations: true },
            chart: {
                type: 'spline',
                spacing: [6, 6, 6, 6]
            },
            credits: { enabled: false },
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    clip: false
                }
            },
            series: [
                {
                    name: 'Current',
                    color: '#2563eb',
                    data: store.analysisStores[index].current().analysisSeries[0]?.values
                },
                {
                    name: 'Previous',
                    color: '#3b82f6',
                    data: store.analysisStores[index].previous().analysisSeries[0]?.values,
                    dashStyle: 'Dash',
                    xAxis: 1
                }
            ],
            title: {
                text: ''
            },
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                //                pointFormatter: function () { return `${this.series.name}<br><strong>${this.y}</strong>` },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' },
                xDateFormat: '%d.%m.%Y'
            },
            xAxis: [
                {
                    type: 'datetime',
                    visible: false
                },
                {
                    type: 'datetime',
                    visible: false
                }
            ],
            yAxis: {
                visible: false,
                min: Math.min(...[
                    ...(store.analysisStores[index].current().analysisSeries[0]?.values || []),
                    ...(store.analysisStores[index].previous().analysisSeries[0]?.values || [])
                ].map(point => point.y)),
                max: Math.max(...[
                    ...(store.analysisStores[index].current().analysisSeries[0]?.values || []),
                    ...(store.analysisStores[index].previous().analysisSeries[0]?.values || [])
                ].map(point => point.y))
            }
        }))
    },

    actions: {
        initialize(filters) {
            this.filters = filters

            this.isLoading = true

            Promise.all([
                this.loadCurrent(),
                this.loadPrevious(),
                this.loadAnalysis(),
                this.loadPreviousAnalysis()
            ]).then(() => {
                this.isInitialized = true
                this.isLoading = false
            })
        },

        async initializeWith(filters, initializer) {
            this.filters = filters

            this.isLoading = true

            await initializer(this)

            this.isInitialized = true
            this.isLoading = false
        },

        loadCurrent() {
            return this.metrics.forEach((m, index) => {
                return content()
                    .filters(this.filters)
                    .query({ aggregates: m.aggregatesQuery })
                    .get(aggregates => this.current[index] = aggregates[m.aggregatesQuery])
            })
        },

        loadPrevious() {
            return this.metrics.forEach((m, index) => {
                if (! this.previousDateRange()) {
                    return this.previous[index] = 0
                }
    
                return content()
                    .filters({
                        ...this.filters.toPerspective(),
                        'date': this.previousDateRange()
                    })
                    .query({ aggregates: m.aggregatesQuery })
                    .get(aggregates => this.previous[index] = aggregates[m.aggregatesQuery])
            })
        },

        loadAnalysis() {
            this.metrics.forEach((m, index) => {
                let store = this.analysisStores[index].current()
                let filters = this.filters.toPerspective()

                store.series = [{
                    label: 'Results',
                    color: '#2563eb',
                    datasetType: 'inline-perspective',
                    datasetFilters: filters,
                    meta: { aggregator: m.analysisAggregator, metric: m.analysisMetric },
                    values: []
                }]
    
                if (filters['date']) {
                    store.date = filters['date']
                    store.granularity = determineGranularity(store.date)
                } else {
                    store.date = { type: 'past', date: { past: 1, unit: 'years' } }
                    store.granularity = 'month'
                }

                return store.loadSeries()
            })
        },

        loadPreviousAnalysis() {
            this.metrics.forEach((m, index) => {
                let store = this.analysisStores[index].previous()
                let filters = this.filters.toPerspective()
    
                store.series = [{
                    label: 'Results',
                    color: '#2563eb',
                    datasetType: 'inline-perspective',
                    datasetFilters: JSON.stringify(filters),
                    meta: { aggregator: m.analysisAggregator, metric: m.analysisMetric },
                    values: []
                }]
    
                store.date = this.previousDateRange()
    
                if (! store.date) {
                    return store.analysisSeries = []
                }
    
                store.granularity = determineGranularity(store.date)
    
                return store.loadSeries()
            })
        },

        previousDateRange() {
            let dateFilter = this.filters.value('date')

            if (! dateFilter) return

            if (dateFilter.type == 'past') {
                if (dateFilter.date.unit == 'days') {
                    return { type: 'in-range', date: { gte: subDays(new Date(), dateFilter.date.past * 2), lte: subDays(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'hours') {
                    return { type: 'in-range', date: { gte: subHours(new Date(), dateFilter.date.past * 2), lte: subHours(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'weeks') {
                    return { type: 'in-range', date: { gte: subWeeks(new Date(), dateFilter.date.past * 2), lte: subWeeks(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'months') {
                    return { type: 'in-range', date: { gte: subMonths(new Date(), dateFilter.date.past * 2), lte: subMonths(new Date(), dateFilter.date.past) } }
                } else if (dateFilter.date.unit == 'years') {
                    return { type: 'in-range', date: { gte: subYears(new Date(), dateFilter.date.past * 2), lte: subYears(new Date(), dateFilter.date.past) } }
                }
            } else if (dateFilter.type == 'in-range') {
                return { type: 'in-range', date: { gte: subHours(dateFilter.date.gte, differenceInHours(dateFilter.date.lte, dateFilter.date.gte)), lte: dateFilter.date.gte } }
            }
        }
    }
})

export default defineOverviewMetricsStore
