<template>
    <div class="py-3">
        <div class="flex items-center">
            <a v-if="reply.publishedBy" :href="$router.resolve({ name: 'channels.channel.information', params: { id: reply.publishedBy.id } }).href" target="_blank">
                <ui-avatar :item="reply.publishedBy" class="w-8 h-8 mr-2"></ui-avatar>
            </a>
            <ui-avatar v-else icon="user" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-8 h-8 mr-2"></ui-avatar>

            <div>
                <div class="text-base font-medium">
                    <a v-if="reply.publishedBy" :href="$router.resolve({ name: 'channels.channel.information', params: { id: reply.publishedBy.id } }).href" target="_blank">
                        {{ reply.publishedBy.name }}
                    </a>
                    <span v-else>
                        Anonymous
                    </span>
                </div>

                <div class="flex items-center space-x-1 text-xs leading-none mt-0.5 text-gray-700">
                    <a class="block" :href="$router.resolve({ name: 'content.details', params: { id: reply.id } }).href" target="_blank">
                        <ui-icon name="time" class="mr-1"></ui-icon>
                        <span>{{ $dateTime(reply.publishedAt) }}</span>
                        <span class="ml-1 bg-red-100 text-red-500 py-0.5 rounded px-2 text-2xs font-medium tracking-tight" v-if="deleted">Deleted</span>
                        <span class="ml-1 bg-emerald-100 text-emerald-600 py-0.5 rounded px-2 text-2xs font-medium tracking-tight" v-if="reply.labels.includes('sentiment_polarity:positive')">Positive</span>
                        <span class="ml-1 bg-rose-100 text-rose-600 py-0.5 rounded px-2 text-2xs font-medium tracking-tight" v-if="reply.labels.includes('sentiment_polarity:negative')">Negative</span>
                        <span class="ml-1 bg-indigo-100 text-indigo-600 py-0.5 rounded px-2 text-2xs font-medium tracking-tight" v-if="reply.labels.includes('sentiment_polarity:neutral')">Neutral</span>
                    </a>

                    <span v-if="reply.metrics.interactions">&middot;</span>

                    <div v-if="reply.metrics.interactions" class="flex items-center shrink-0">
                        <ui-icon name="interactions-solid" class="mr-1"></ui-icon>
                        <span>{{ $number(reply.metrics.interactions) }}</span>
                    </div>

                    <span v-if="reply.metrics.views">&middot;</span>

                    <div v-if="reply.metrics.views" class="flex items-center shrink-0">
                        <ui-icon name="views" class="mr-1"></ui-icon>
                        <span>{{ $number(reply.metrics.views) }}</span>
                    </div>

                    <span>&middot;</span>

                    <a :href="$router.resolve({ name: 'content.details', params: { id: reply.id } }).href" target="_blank" class="text-blue-600 hover:text-blue-800">
                        Show details
                    </a>

                    <template v-if="isTranslatable">
                        <span>&middot;</span>

                        <a href="#" @click.prevent="translate(reply)" class="text-blue-600 hover:text-blue-800">
                            Translate
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <div class="text-base mt-3">
            <div class="bg-gray-100 rounded-sm px-2 py-1 mb-1" v-if="reply.translation?.isLoading || reply.translation?.text">
                <div class="text-xs text-gray-800 font-semibold italic mb-1">
                    <ui-icon name="translate"></ui-icon>
                    Translated text
                </div>

                <div v-if="reply.translation?.isLoading" class="flex items-center justify-center py-4">
                    <ui-spinner></ui-spinner>
                </div>
                <div class="whitespace-pre-line break-words" v-else v-html="reply.translation?.text"></div>
            </div>

            <span class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></span>
            <a href="#" v-if="! isShowingFullText" class="ml-1 text-blue-600" @click.prevent="readMore">Read more</a>
        </div>
        <div v-if="reply.images.length" class="flex flex-wrap mt-2">
            <img class="w-16 h-16 mr-2 object-contain rounded-md border border-gray-400 hover:border-gray-700 cursor-pointer" @click="showMedia(i)" :src="image.url" v-for="image, i in reply.images" :key="i" alt="">
        </div>

        <div class="px-3" v-if="reply.replies?.length">
            <component :is="repliesComponent" :parent="reply" :replies="reply.replies"></component>
        </div>
        <div v-else-if="reply.repliesCount">
            <a href="#" @click.prevent="loadAdditionalReplies(reply)" class="block text-blue-600 text-sm my-1">
                Show <span class="font-medium">{{reply.repliesCount}}</span> replies
            </a>
        </div>
    </div>
</template>

<script>
import useDetailsStore from '@/stores/content/details'
import useRepliesStore from '@/stores/content/details/replies'

import language from '@/helpers/language'
import { translate } from '@/helpers/translations'

import { mapActions } from 'pinia'

export default {
    props: [ 'reply', 'repliesComponent' ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isRTL() {
            return language(this.reply.language[0]).rtl
        },

        isShowingFullText() {
            return this.showFullText || (this.reply.body || '').length <= 300
        },

        isTranslatable() {
            return language(this.reply.language[0]).translatable
        },

        text() {
            return this.isShowingFullText ? this.reply.body : this.reply.body.replace(/\n\s*\n/g, "\n").substr(0, 300) + '...'
        },

        deleted() {
            return this.reply.labels.filter(l => l === 'deleted').length
        }
    },

    methods: {
        translate,
        ...mapActions(useRepliesStore, [ 'loadAdditionalReplies' ]),

        readMore() {
            this.showFullText = true
        },

        showMedia(currentIndex) {
            useDetailsStore().streamStore.mediaLightbox.open(this.reply.images, currentIndex)
        }
    }
}
</script>
