import useMyStore from '@/stores/me/my'
import useMyAlertsStore from '@/stores/me/alerts'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useQuickSearchStore from '@/stores/me/quick-search'

import api, { channels, content } from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { sortAlphabetically } from '@/helpers/sorting'

export const useAlertEditStore = defineForm({
    id: 'alertsModalsAlertEdit',

    inputs: () => ({
        name: null,
        type: null,
        subject: null,
        recipients: null,
        delivery: null,
        settings: {}
    }),

    state: () => ({
        alert: null,
        subject: null,

        lockSubjectSelection: false,

        deliveryOptions: [
            {
                id: 'in-app',
                name: 'In-App',
                description: 'Receive alerts as notifications in the Juno app itself.',
                icon: 'bell',
                settings: { enabled: false }
            },
            {
                id: 'email',
                name: 'Email',
                description: 'Receive alerts as emails.',
                icon: 'mail',
                settings: { enabled: false, extraRecipients: '' }
            },
            {
                id: 'sms',
                name: 'SMS',
                description: 'Receive alerts as SMS messages.',
                icon: 'smartphone',
                settings: { enabled: false, extraRecipients: '' }
            },
            {
                id: 'slack',
                name: 'Slack',
                description: 'Receive alerts as Slack messages.',
                icon: 'slack',
                settings: { enabled: false, oauthToken: null, username: null, channel: null }
            },
//            {
//                id: 'microsoft-teams',
//                name: 'Microsoft Teams',
//                description: 'Receive alerts as Microsoft Teams messages.',
//                icon: 'microsoft-teams',
//                settings: { enabled: false, webhookUrl: null }
//            },
            {
                id: 'mattermost',
                name: 'Mattermost',
                description: 'Receive alerts as Mattermost messages.',
                icon: 'mattermost',
                hidden: true,
                settings: { enabled: false, webhookUrl: null, username: null, channel: null }
            },
            {
                id: 'signal',
                name: 'Signal',
                description: 'Receive alerts as Signal messages.',
                icon: 'signal',
                hidden: true,
                settings: { enabled: false, extraRecipients: '' }
            },
//            {
//                id: 'telegram',
//                name: 'Telegram',
//                description: 'Receive alerts as Telegram messages.',
//                icon: 'telegram',
//                hidden: true,
//                settings: { enabled: false }
//            },
//            {
//                id: 'webhook',
//                name: 'Webhook',
//                description: 'Call a HTTP webhook on every alert.',
//                icon: 'globe',
//                hidden: true,
//                settings: { enabled: false, url: '' }
//            }
        ]
    }),

    getters: {
        type: store => {
            return useMyAlertsStore().types.find(type => type.id == store.inputs.type)
        },

        defaultName: store => `${store.subject?.name ?? 'Untitled'} (${store.type?.name})`
    },

    submitRequest() {
        return api.route(this.alert?.id ? 'me alerts update' : 'me alerts store', { id: this.alert?.id })
            .json({
                _method: this.alert?.id ? 'put' : 'post',
                name: this.inputs.name || this.defaultName,
                type: this.inputs.type,
                subject: { family: this.inputs.subject.family, id: this.inputs.subject.id },
                recipients: Object.entries(this.inputs.recipients).filter(([ _, enabled ]) => enabled).map(([ user, _ ]) => user),
                delivery: this.inputs.delivery,
                settings: this.inputs.settings
            })
            .post()
    },

    async onResponse(res) {
        if (this.subject.alerts) {
            this.alert?.id ? Object.assign(this.subject.alerts.find(a => a.id == this.alert.id), res.data) : this.subject.alerts.push(res.data)
        }

        await useMyAlertsStore().reload()

        useModal().hide('alert-edit')

        this.fulfillPromise()
    },

    actions: {
        open(alert = {}, subject = null, lockSubjectSelection = false) {
            this.reset()

            this.alert = alert
            this.subject = subject

            this.lockSubjectSelection = lockSubjectSelection

            this.inputs.name = alert?.name
            this.inputs.type = alert?.type
            this.inputs.subject = alert?.subject || subject
            this.inputs.recipients = (alert?.recipients || [ '*' ])
                .reduce((recipients, recipient) => ({ ...recipients, [recipient]: true }), {})
            this.inputs.delivery = this.deliveryOptions
                .reduce((options, option) => ({ ...options, [option.id]: { ...option.settings, ...(alert?.delivery?.[option.id] || {}) } }), {})
            this.inputs.settings = { ...useMyAlertsStore().types.find(t => t.id == alert?.type).settings, ...(alert?.settings || {}) }

            this.resolveSubject(alert?.subject)

            useModal().show('alert-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('alert-edit')

            this.fulfillPromise()
        },

        async delete() {
            useMyAlertsStore().delete(this.alert)

            useModal().hide('alert-edit')

            this.fulfillPromise()
        },

        selectSubject() {
            useQuickSearchStore().show({
                families: this.type.subjectFamilies,
                limit: 50,
                onSelect: result => {
                    this.resolveSubject({ id: result.id, family: `${result.resultType}s`, name: result.title })

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id,
                        resultType: 'perspective',
                        title: item.name
                    })),
                    ...sortAlphabetically(useMyChannelListsStore().items).map(item => ({
                        id: item.id,
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        },

        async resolveSubject(subject) {
            this.inputs.subject = subject

            if (this.subject) return

            if (subject?.family == 'channels') {
                this.subject = await channels().find(subject.id)
            } else if (subject?.family == 'lists') {
                await useMyChannelListsStore().initialize()
                this.subject = useMyChannelListsStore().find(subject.id)
            } else if (subject?.family == 'perspectives') {
                await useMyPerspectivesStore().initialize()
                this.subject = useMyPerspectivesStore().find(subject.id)
            } else if (subject?.family == 'content') {
                this.subject = await content().find(subject.id)
            }
        },

        toggleRecipient(recipient) {
            this.inputs.recipients[recipient] = ! this.inputs.recipients[recipient]

            if (recipient == '*') {
                useMyStore().currentWorkspace.users.forEach(user => this.inputs.recipients[user.id] = false)
            } else {
                this.inputs.recipients['*'] = false
            }
        },

        toggleDeliveryOption(option) {
            this.inputs.delivery[option].enabled = ! this.inputs.delivery[option].enabled
        }
    }
})

export default useAlertEditStore
