export const topics = [
    {
        id: 'conflict', name: 'Conflict',
        children: [
            { id: 'conflict.general', name: 'General' },
            { id: 'conflict.act-of-terror', name: 'Act Of Terror' },
            { id: 'conflict.armed-conflict', name: 'Armed Conflict' },
            { id: 'conflict.civil-unrest', name: 'Civil Unrest' },
            { id: 'conflict.coup-detat', name: 'Coup Detat' },
            { id: 'conflict.cyber-warfare', name: 'Cyber Warfare' },
            { id: 'conflict.massacre', name: 'Massacre' },
            { id: 'conflict.peace-process', name: 'Peace Process' },
            { id: 'conflict.postwar-reconstruction', name: 'Postwar Reconstruction' },
            { id: 'conflict.prisoners-of-war', name: 'Prisoners Of War' }
        ]
    },
    {
        id: 'crime-and-law', name: 'Crime And Law',
        children: [
            { id: 'crime-and-law.general', name: 'General' },
            { id: 'crime-and-law.crime', name: 'Crime' },
            { id: 'crime-and-law.judiciary', name: 'Judiciary' },
            { id: 'crime-and-law.justice', name: 'Justice' },
            { id: 'crime-and-law.law', name: 'Law' },
            { id: 'crime-and-law.law-enforcement', name: 'Law Enforcement' }
        ]
    },
    {
        id: 'culture-and-media', name: 'Culture And Media',
        children: [
            { id: 'culture-and-media.general', name: 'General' }
        ]
    },
    {
        id: 'disaster-and-accident', name: 'Disaster And Accident',
        children: [
            { id: 'disaster-and-accident.general', name: 'General' }
        ]
    },
    {
        id: 'economy-and-business', name: 'Economy And Business',
        children: [
            { id: 'economy-and-business.general', name: 'General' },
            { id: 'economy-and-business.business-information', name: 'Business Information' },
            { id: 'economy-and-business.economy', name: 'Economy' },
            { id: 'economy-and-business.market-and-exchange', name: 'Market And Exchange' },
            { id: 'economy-and-business.products-and-services', name: 'Products And Services' }
        ]
    },
    {
        id: 'education', name: 'Education',
        children: [
            { id: 'education.general', name: 'General' }
        ]
    },
    {
        id: 'environment', name: 'Environment',
        children: [
            { id: 'environment.general', name: 'General' }
        ]
    },
    {
        id: 'health', name: 'Health',
        children: [
            { id: 'health.general', name: 'General' }
        ]
    },
    {
        id: 'human-interest', name: 'Human Interest',
        children: [
            { id: 'human-interest.general', name: 'General' }
        ]
    },
    {
        id: 'labour', name: 'Labour',
        children: [
            { id: 'labour.general', name: 'General' }
        ]
    },
    {
        id: 'lifestyle', name: 'Lifestyle',
        children: [
            { id: 'lifestyle.general', name: 'General' }
        ]
    },
    {
        id: 'other', name: 'Other',
        children: [
            { id: 'other.general', name: 'General' }
        ]
    },
    {
        id: 'politics', name: 'Politics',
        children: [
            { id: 'politics.general', name: 'General' },
            { id: 'politics.election', name: 'Election' },
            { id: 'politics.fundamental-rights', name: 'Fundamental Rights' },
            { id: 'politics.government', name: 'Government' },
            { id: 'politics.government-policy', name: 'Government Policy' },
            { id: 'politics.international-relations', name: 'International Relations' },
            { id: 'politics.nongovernmental-organisation', name: 'Nongovernmental Organisation' },
            { id: 'politics.political-crisis', name: 'Political Crisis' },
            { id: 'politics.political-dissent', name: 'Political Dissent' },
            { id: 'politics.political-process', name: 'Political Process' }
        ]
    },
    {
        id: 'religion', name: 'Religion',
        children: [
            { id: 'religion.general', name: 'General' }
        ]
    },
    {
        id: 'society', name: 'Society',
        children: [
            { id: 'society.general', name: 'General' }
        ]
    },
    {
        id: 'science-and-technology', name: 'Science And Technology',
        children: [
            { id: 'science-and-technology.general', name: 'General' }
        ]
    },
    {
        id: 'sport', name: 'Sport',
        children: [
            { id: 'sport.general', name: 'General' }
        ]
    },
    {
        id: 'weather', name: 'Weather',
        children: [
            { id: 'weather.general', name: 'General' }
        ]
    }
]

export function iptcTopic(topic) {
    topic = { ...topics.flatMap(t => [ t, ...t.children ]).find(t => t.id == topic) }

    if (! topic) return {}

    if (topic.id.includes('.')) {
        let parentId = topic.id.split('.')[0]
        topic.parent = { ...topics.find(t => t.id == parentId) }
    }

    topic.icon = `iptc.${topic.parent?.id || topic.id}`
    topic.fullName = topic.parent ? `${topic.parent.name} - ${topic.name}` : topic.name

    return topic
}
