<template>
    <div class="px-6 xl:px-16 max-w-7xl mx-auto my-8 pb-6">
        <ui-async :guards="content">
            <div v-if="content?.id" class="max-w-3xl mx-auto">
                <content-card :content="content" :stream-store="streamStore" mode="details"></content-card>
            </div>

            <div v-else class="h-screen flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="navigation.content" class="text-4xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Content not found.</div>
            </div>

            <Teleport to="#root">
                <stream-lightbox :store="streamStore.mediaLightbox"></stream-lightbox>
            </Teleport>
        </ui-async>
    </div>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'
import StreamLightbox from './perspective/stream/lightbox'

import useContentDetailsStore from '@/stores/content/details'

import { mapState } from 'pinia'

export default {
    components: {
        ContentCard, StreamLightbox
    },

    computed: {
        ...mapState(useContentDetailsStore, [ 'content', 'streamStore' ])
    }
}
</script>
