<template>
    <div class="flex items-center justify-center w-full h-full">
        <div class="relative" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }">
            <img v-if="video.thumbnailUrl" :src="video.thumbnailUrl" class="object-contain" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }">
            <div v-else class="object-contain bg-gray-200" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }"></div>

            <div v-if="! isPlaying" class="absolute inset-0 flex items-center justify-center" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }">
                <a v-if="video.url" @click.prevent="play" href="#" class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 text-white rounded-full">
                    <ui-icon name="play" class="text-3xl"></ui-icon>
                </a>

                <div v-else class="flex flex-col gap-y-0.5 items-center justify-center p-5 bg-gray-900 bg-opacity-90 text-white rounded-lg">
                    <ui-icon name="video" class="text-5xl text-gray-100"></ui-icon>
                    <div>{{ video.originalUrl ? 'Third-party video' : 'Video is not available' }}</div>
                    <a v-if="video.originalUrl" href="#" @click.prevent="play" class="text-gray-300 hover:text-white">
                        <ui-icon name="play-circle"></ui-icon> Play original
                    </a>
                    <a v-else-if="supportsVideoDownload" href="#" @click.prevent="downloadVideo" class="text-gray-300 hover:text-white">
                        <ui-icon name="download"></ui-icon> Download
                    </a>
                </div>
            </div>

            <div v-if="isPlaying" class="absolute inset-0 z-10">
                <video :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }" controls autoplay v-if="url">
                    <source :src="url" type="video/mp4">
                </video>

                <iframe :src="originalUrl" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }" scrolling="no" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true" v-else>
                </iframe>
            </div>
        </div>
    </div>
</template>

<script>
import useContentModalsVideoDownloadStore from '@/stores/content/modals/video-download'

import { alert } from '@/helpers/modals'

export default {
    props: [ 'content', 'video' ],

    data: () => ({
        isPlaying: false
    }),

    computed: {
        type() {
            return this.video.key ? 'video' : 'iframe'
        },

        orientation() {
            return this.video.height
                ? (this.video.height > this.video.width ? 'portrait' : 'landscape')
                : (this.video.thumbnail?.height > this.video.thumbnail?.width ? 'portrait' : 'landscape')
        },

        url() {
            return this.video.url
        },

        originalUrl() {
            return this.video.originalUrl ? `${this.video.originalUrl}${this.video.originalUrl.includes('?') ? '&autoplay=true' : '?autoplay=true'}` : null
        },

        supportsVideoDownload() {
            return [ 'facebook-post', 'telegram-channel-post', 'x-post', 'vkontakte-post', 'youtube-video' ].includes(this.content?.type)
        }
    },

    methods: {
        play() {
            if (! this.video.url) {
                return alert({
                    title: 'Third-party content',
                    body: 'You are accessing a third-party resource, your connection information will be exposed to a third-party provider if you proceed.',
                    muteable: { id: 'play-original-video', expires: 30 }
                }).then(() => {
                    this.isPlaying = true
                })
            }

            this.isPlaying = true
        },

        downloadVideo() {
            useContentModalsVideoDownloadStore().open(this.content, this.video)
        }
    }
}
</script>
