import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyChannelListsStore from '@/stores/me/channel-lists'

export const useChannelsModalsListEditStore = defineForm({
    id: 'channelsModalsListEdit',

    inputs: () => ({
        list: {},
        name: null,
        description: null,
        avatar: {}
    }),

    submitRequest() {
        if (! this.inputs.list.id) {
            trackEvent('channels', 'new-channel-list-saved')
        }

        let route = this.inputs.list.id
            ? [ 'me lists update', this.inputs.list.id ]
            : [ 'me lists store' ]

        return api.route(...route)
            .json({
                _method: this.inputs.list.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: {
                    color: this.inputs.avatar.color,
                    icon: this.inputs.avatar.icon,
                    imageId: this.inputs.avatar.imageId
                }
            })
            .post()
    },

    async onResponse(res) {
        await useMyChannelListsStore().reload()

        useModal().hide('channels-list-edit')

        if (! this.inputs.list.id) {
            useRouter().push({ name: 'channels', query: { show: `list|id:${res.data.id}` } })
        }
    },

    actions: {
        open(list = {}) {
            this.reset()

            this.inputs.list = list
            this.inputs.name = list.name
            this.inputs.description = list?.description
            this.inputs.avatar = list?.avatar || {}

            useModal().show('channels-list-edit')
        },

        cancel() {
            useModal().hide('channels-list-edit')
        },

        async delete() {
            try {
                await useMyChannelListsStore().delete({ id: this.inputs.list.id })
            } catch (e) {
                return
            }

            useModal().hide('channels-list-edit')
            useRouter().push({ name: 'channels.my-lists' })
        }
    }
})

export default useChannelsModalsListEditStore
