<template>
    <ui-modal name="import-perspective" title="Import perspective data to your graph" :max-width="520" :max-height="680">
        <div class="flex gap-x-8">
            <div class="flex-1">
                <div v-if="!perspectiveLoading">
                    <ui-select placeholder="Select a perspective" :options="perspectives" :value="selected?.id" @change="selectPerspective($event)" class="w-full"></ui-select>
                </div>
            </div>
        </div>

        <ui-spinner v-if="perspectiveLoading" type="clip" class="text-3xl"></ui-spinner>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="blue" :action="submit" :disabled="!selected">Import</ui-button>
        </div>
    </ui-modal>
</template>

<script>

import useConnectionsDataStore from "@/stores/connections/connections-data"
import { mapActions, mapState } from "pinia"

export default {

    data: () => ({
        selected: null,
    }),

    computed: {
        ...mapState(useConnectionsDataStore, [ 'perspectives', 'perspectiveLoading' ]),
    },

    methods: {
        ...mapActions(useConnectionsDataStore, [ 'loadPerspectiveStats' ]),

        selectPerspective(perspective_id) {
            this.selected = this.perspectives.find(p => p.id === perspective_id)
        },

        async submit() {
            await this.loadPerspectiveStats(this.selected)
            this.selected = null
        }
    }
}
</script>
