import defineAnalysisWidget from './widgets/analysis'
import defineStreamWidget from './widgets/stream'
import defineMetricsWidget from '@/stores/dashboards/widgets/metrics'

import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'
import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'
import searchFilters from '@/helpers/search-filters'

import compare from 'just-compare'
import { defineStore } from 'pinia'

export const useBoardStore = defineStore({
    id: 'board',

    state: () => ({
        board: {},
        boardLoaded: false,
        boardPromise: null,

        contents: null,

        filters: searchFilters(),

        isBeingSaved: false,
        isEditing: false,

        lastWidgetId: 0,

        lastRefresh: null,
        isRefreshing: false,

        fullscreenMode: false,

        widgetTypes: [
            {
                id: 'analysis',
                name: 'Analysis',
                minW: 1, minH: 2, startingW: 2, startingH: 2,
                defineStore: defineAnalysisWidget
            },
            {
                id: 'stream',
                name: 'Stream',
                icon: 'stream',
                minW: 1, minH: 1, startingW: 1, startingH: 2,
                defineStore: defineStreamWidget
            },
            {
                id: 'metrics',
                name: 'Metrics',
                icon: 'text-word',
                minW: 1, minH: 1, startingW: 1, startingH: 2,
                defineStore: defineMetricsWidget
            }
        ]
    }),

    getters: {
        hasUnsavedChanges() {
            console.log(this.filters.toPerspective(), this.board.meta?.filters, compare(this.filters.toPerspective(), this.board.meta?.filters))
            return ! compare(this.filters.toPerspective(), this.board.meta?.filters)
        }
    },

    actions: {
        async initialize(id) {
            this.$reset()

            this.filters.set('date', { type: 'past', date: { past: 1, unit: 'years' } })

            await this.load(id)

            this.startUpdating()

            this.filters.onChange = () => {
                if (! this.filters.value('date')) return this.filters.set('date', { type: 'past', date: { past: 1, unit: 'years' } })

                this.contents.forEach(w => w.filtersUpdated?.())
            }
        },

        async load(id, force = false) {
            if (this.boardLoaded && ! force) return Promise.resolve()
            if (this.boardPromise) return this.boardPromise

            return this.boardPromise = api.route('me dashboards details', { id }).get().json(res => {
                this.board = res.data
                this.boardLoaded = true
                this.boardPromise = null

                this.filters.fromPerspective(this.board.meta.filters || {})

                this.contents = this.board.contents.map(w => this.loadWidget(w))
            })
        },

        async reload() {
            if (! this.board.id) return

            return this.load(this.board.id, true)
        },

        edit() {
            useModalsBoardEditStore().cancel()

            this.isEditing = true
        },

        save() {
            this.isBeingSaved = true

            this.contents.forEach(w => w.isNew = false)

            let contents = this.contents.map(w => ({
                x: w.x, y: w.y,
                w: w.w, h: w.h,
                type: w.type.id,
                state: w.serialize(),
                sorting: w.sorting
            }))

            let dependencies = this.contents
                .map(w => w.dependencies())
                .reduce((allDeps, deps) => {
                    Object.entries(deps).forEach(([ relation, ids ]) => {
                        allDeps[relation] = [ ...(allDeps[relation] || []), ...ids.filter(v => v) ]
                    })
                    return allDeps
                }, {})

            let meta = {
                filters: this.filters.toPerspective()
            }

            return api.route('me dashboards update', { id: this.board.id }).json({
                _method: 'put',
                name: this.board.name,
                description: this.board.description,
                contents,
                meta,
                dependencies
            }).post().json(res => {
                this.board = res.data

                this.isBeingSaved = false
                this.isEditing = false
            })
        },

        addWidget(type) {
            let definition = this.widgetTypes.find(t => t.id == type)
            let lastNonEmptyRow = Math.max(...this.contents.map(w => w.y + w.h - 1))

            let widget = this.createWidget(definition, {
                x: 0, y: lastNonEmptyRow > 0 ? lastNonEmptyRow + 1 : 0,
                w: definition.startingW ?? definition.minW, h: definition.startingH ?? definition.minH,
                isNew: true
            })

            this.contents.push(widget)

            widget.added()

            return widget
        },

        loadWidget(widget) {
            let type = this.widgetTypes.find(t => t.id == widget.type)

            return this.createWidget(type, { ...widget, ...widget.state })
        },

        createWidget(type, settings) {
            let index = this.lastWidgetId++

            let store = type.defineStore({
                id: `dashboard${this.board.id}Widgets${index}`,
                i: `board-${this.board.id}-${type.id}-${index}`,
                ...settings,
                type
            })

            return store().initialize(this.filters)
        },

        async deleteWidget(widget) {
            await useDeleteConfirmationModal().open('Dashboard widget')
                .then(() => {
                    this.contents = this.contents.filter(w => w !== widget)
                    widget.destroy()
                })
        },

        startUpdating() {
            this.refreshInterval = setInterval(() => {
                this.isRefreshing = true

                this.contents.forEach(widget => {
                    if (widget.lastRefresh + widget.refreshInterval * 60 * 1000 < + new Date) {
                        widget.refresh()
                    }
                })

                setTimeout(() => {
                    this.isRefreshing = false
                    this.lastRefresh = +new Date
                }, 2000)
            }, 5 * 60 * 1000)
        },

        stopUpdating() {
            clearInterval(this.refreshInterval)
        },

        layoutUpdated() {
            this.contents.forEach(w => w.layoutUpdated())
        },

        toggleFullscreenMode() {
            this.fullscreenMode = ! this.fullscreenMode
        }
    }
})

export default useBoardStore
