<template>
    <div class="mb-3 border-l-2 border-gray-200 pl-3 text-sm">
        <div class="flex items-center">
            <a :href="$router.resolve({ name: 'channels.channel.information', params: { id: root.publishedBy.id } }).href" target="_blank">
                <ui-avatar :item="root.publishedBy" class="h-7 w-7 mr-2"></ui-avatar>
            </a>

            <div>
                <a class="font-medium" :href="$router.resolve({ name: 'channels.channel.information', params: { id: root.publishedBy.id } }).href" target="_blank">
                    {{ root.publishedBy.name }}
                </a>

                <a class="flex items-center text-2xs leading-4 text-gray-700" :href="$router.resolve({ name: 'content.details', params: { id: root.id } }).href" target="_blank">
                    <ui-icon name="time" class="mr-1"></ui-icon>
                    <span>{{ $dateTime(root.publishedAt) }}</span>
                </a>
            </div>
        </div>
        <div class="mt-2">
            <span class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></span>
            <a href="#" v-if="! isShowingFullText" class="ml-1 text-blue-600" @click.prevent="readMore">Read more</a>
        </div>
        <div v-if="root.images.length" class="flex flex-wrap mt-2">
            <img class="w-12 h-12 mr-2 object-contain rounded-md border border-gray-400 hover:border-gray-700 cursor-pointer" @click="showMedia(i)" :src="image.src" v-for="image, i in root.images" :key="i" alt="">
        </div>
    </div>
</template>

<script>
import language from '@/helpers/language'

export default {
    props: [ 'parent', 'root', 'streamStore' ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isRTL() {
            return language(this.root.language[0]).rtl
        },

        isShowingFullText() {
            return this.showFullText || (this.root.body || '').length <= 100
        },

        text() {
            return this.isShowingFullText ? this.root.body : this.root.body.replace(/\n\n/, "\n").substr(0, 100) + '...'
        }
    },

    methods: {
        readMore() {
            this.showFullText = true
            this.streamStore.triggerLayoutUpdate([ this.parent ])
        },

        showMedia(currentIndex) {
            this.streamStore.mediaLightbox.open(this.root.images, currentIndex)
        }
    }
}
</script>
