import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import api from '@/api'

export const useChannelsModalsHistoryDownloadStore = defineStore({
    id: 'channelsModalsHistoryDownload',

    state: () => ({
        channel: null
    }),

    getters: {
        isDownloaded(store) {
            return false
        },

        isSupported(store) {
//            let supportedTypes = [
//                'facebook-page', 'instagram-profile', 'telegram-channel', 'telegram-group', 'tik-tok-user',
//                'x-user', 'vkontakte-community', 'vkontakte-user', 'youtube-channel'
//            ]
            let supportedTypes = []

            return supportedTypes.includes(store.channel?.type)
        }
    },

    actions: {
        open(channel) {
            this.$reset()

            this.channel = channel

            useModal().show('channels-history-download')
        },

        cancel() {
            useModal().hide('channels-history-download')
        },

        request() {
            api.route('monitor channels download-history', { id: this.channel.id }).post()

            useModal().hide('channels-history-download')
        }
    }
})

export default useChannelsModalsHistoryDownloadStore
