export function arrayJoin(input, glue = ',', lastGlue = ' and ') {
    if (input.length < 2) return input[0]

    return input.slice(0, -1).join(glue) + lastGlue + input[input.length - 1]
}

export function arrayWrap(input) {
    if (! input) return []

    return input instanceof Array ? input : [ input ]
}
