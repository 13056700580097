import api from '@/api'

export function loadUnmodified(item) {
    item.unmodified = { isLoading: true, title: undefined, body: undefined }

    return api.route('monitor content unmodified', { id: item.id })
        .post()
        .json(data => {
            item.unmodified.title = data.title
            item.unmodified.body = data.body
            item.unmodified.incomplete = data.incomplete
        })
        .finally(() => {
            item.unmodified.isLoading = false
        })
}

export function removeUnmodified(item) {
    item.unmodified = undefined
}

export default loadUnmodified
