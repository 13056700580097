<template>
    <router-link :to="{ name: 'alerts', query: { show: `alert|id:${alert.id}` } }" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-200 shadow-sm hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4" v-tooltip="alert.description">
                <div class="w-2.5 h-2.5 shrink-o rounded-full mr-3" :class="alert.unreadTriggers ? 'bg-blue-600' : 'bg-gray-200'" v-tooltip="`${alert.unreadTriggers || 'No'} unread triggers.`"></div>

                <ui-avatar :item="alert" class="w-8 h-8 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{alert.name}}
                    </h1>
                </div>

                <div class="shrink-0">
                    <contextual-menu :alert="alert" plain></contextual-menu>
                </div>
            </div>
        </div>
    </router-link>

    <router-link :to="{ name: 'alerts', query: { show: `alert|id:${alert.id}` } }" class="block hover:bg-gradient-to-r from-transparent via-gray-50 to-transparent transition" v-else-if="mode == 'row'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0" v-tooltip="alert.description">
                <div class="w-2.5 h-2.5 shrink-o rounded-full mr-3" :class="alert.unreadTriggers ? 'bg-blue-600' : 'bg-gray-200'" v-tooltip="`${alert.unreadTriggers || 'No'} unread triggers.`"></div>

                <ui-avatar :item="alert" class="w-7 h-7 shrink-0"></ui-avatar>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{alert.name}}
                    </h1>
                </div>

                <div class="flex items-center gap-x-2">
                    <span class="text-gray-500 text-sm" v-tooltip="'Pinned'" v-if="alert.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>
                </div>
            </div>

            <div class="flex items-center space-x-3 px-3">
                <div class="w-36 flex items-center">
                    <div class="font-medium text-sm bg-gray-200 inline-flex gap-x-1 items-center rounded-full h-6 px-2.5" v-tooltip="type.description">
                        <ui-icon :name="type.icon"></ui-icon>
                        {{type.name}}
                    </div>
                </div>

                <div class="w-36">
                    <div class="font-medium text-sm flex items-center gap-x-1.5">
                        <div v-if="recipients.length == 1" class="flex-1 flex items-center gap-x-1">
                            <ui-avatar :item="recipients[0]" class="h-5 w-5"></ui-avatar>
                            {{recipients[0].name}}
                        </div>
                        <div v-else class="flex -space-x-1 relative z-0 overflow-hidden  cursor-default">
                            <ui-avatar v-for="recipient, index in recipients.slice(0, 6)" :item="recipient" class="relative inline-block h-5 w-5 rounded-full ring-1 ring-white" :class="`z-${50 - index * 10}`" v-tooltip="recipient.name" :key="recipient.id"></ui-avatar>
                        </div>
                        <div v-if="recipients.length > 6" class="font-medium text-xs text-gray-800 cursor-default" v-tooltip="additionalRecipientsTooltip">
                            +{{recipients.length - 6}}
                        </div>
                    </div>
                </div>

                <div class="w-36">
                    <div class="font-medium text-sm flex items-center gap-x-1.5">
                        <div v-if="delivery.length == 1" class="flex items-center gap-x-1 bg-gray-200 rounded-full h-6 px-2.5">
                            <ui-icon :name="delivery[0].icon"></ui-icon>
                            {{delivery[0].name}}
                        </div>
                        <div v-else class="flex -space-x-1 relative z-0 overflow-hidden cursor-default">
                            <ui-avatar v-for="delivery, index in delivery.slice(0, 6)" :icon="delivery.icon" class="relative inline-block h-5 w-5 rounded-full ring-1 ring-white" :class="`z-${50 - index * 10}`" v-tooltip="delivery.name" :key="delivery.id"></ui-avatar>
                        </div>
                        <div v-if="delivery.length > 6" class="font-medium text-xs text-gray-800 cursor-default" v-tooltip="additionalDeliveryTooltip">
                            +{{delivery.length - 6}}
                        </div>
                    </div>
                </div>

                <div class="w-24">
                    <div class="font-medium text-sm">
                        <span v-if="alert.paused" class="rounded-full bg-red-200 text-red-900 inline-flex items-center h-6 px-2.5">
                            Paused
                        </span>
                        <span v-else-if="alert.muted" v-tooltip="`Muted until ${$dateTime(alert.muted)}`" class="rounded-full bg-orange-200 text-orange-900 inline-flex items-center h-6 px-2.5">
                            Muted
                        </span>
                        <span v-else  v-tooltip="{ content: `Last checked at: ${$dateTime(alert.lastCheckedAt)}<br>Last triggered at: ${$dateTime(alert.lastTrigerredAt)}`, html: true }" class="rounded-full bg-green-200 text-green-900 inline-flex items-center h-6 px-2.5">
                            Active
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :alert="alert" plain></contextual-menu>
                </slot>
            </div>
        </div>
    </router-link>
</template>

<script>
import ContextualMenu from '@/components/alerts/menus/alert'

import useAlertEditStore from '@/stores/alerts/modals/alert-edit'
import useMyStore from '@/stores/me/my'
import useMyAlertsStore from '@/stores/me/alerts.js'

export default {
    props: { alert: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    computed: {
        type() {
            return useMyAlertsStore().types.find(type => type.id == this.alert.type)
        },

        recipients() {
            return this.alert.recipients.includes('*')
                ? useMyStore().currentWorkspace.users
                : useMyStore().currentWorkspace.users.filter(user => this.alert.recipients.includes(user.id))
        },

        additionalRecipientsTooltip() {
            return this.recipients.slice(6).map(recipient => recipient.name).join(', ')
        },

        delivery() {
            return useAlertEditStore().deliveryOptions.filter(option => this.alert.delivery[option.id]?.enabled)
        },

        additionalDeliveryTooltip() {
            return this.delivery.slice(6).map(delivery => delivery.name).join(', ')
        }
    }
}
</script>
