import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export const defineForm = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            submitting: false,
            submitted: false,

            inputs: settings.inputs.apply(this),

            errors: {},

            promise: null,
            fulfillPromise: null,
            rejectPromise: null,

            ...(settings.state ? settings.state() : {})
        }),

        getters: {
            hasErrors(state) {
                return Object.keys(state.errors).length > 0
            },

            ...(settings.getters || {})
        },

        actions: {
            async submit() {
                this.errors = {}
                this.submitted = false
                this.submitting = true

                let request = await settings.submitRequest.apply(this)

                if (! request) {
                    this.submitted = this.submitting = false
                    return Promise.resolve()
                }

                return new Promise((accept, reject) => {
                    request
                        .error(422, err => {
                            this.errors = err.json.errors
                            this.submitting = false

                            nextTick(() => {
                                document.querySelector('input.has-errors, textarea.has-errors, select.has-errors')?.scrollIntoView(false)
                            })
                        })
                        .json(async res => {
                            accept(await settings.onResponse.apply(this, [ res ]))

                            this.submitting = false
                            this.submitted = true
                        })
                })
            },

            reset() {
                this.$reset()
            },

            makePromise() {
                return this.promise = new Promise((accept, reject) => {
                    this.fulfillPromise = accept
                    this.rejectsPromise = reject
                })
            },

            ...(settings.actions || {})
        }
    })
}

export default defineForm
