<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg p-4 min-w-0 flex flex-col" :class="isComparing ? 'h-80' : 'h-72'">
        <div class="flex justify-between pb-2">
            <h1 class="text-lg font-medium text-gray-700">Topics</h1>
            <div class="flex items-center space-x-3">
                <a @click.prevent="store.toggleType()" href="#" class="flex items-center font-medium text-gray-700">
                    {{ type == 'breakdown' ? 'Breakdown' : 'Top-level' }} <ui-icon name="select" class="text-xs"></ui-icon>
                </a>
                <ui-data-quality-icon quality="ai-processed"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3">
                    <span class="rounded px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <div class="space-y-1 mt-2" v-if="store.analysisStore(series).analysisSeries?.length">
                    <div :key="topic.name" v-for="topic in store.analysisStore(series).analysisSeries[0].values.slice(0, 6)">
                        <div class="flex justify-between gap-x-1.5">
                            <a :href="topic.url" target="_blank" class="flex items-center space-x-1.5 font-medium truncate" v-tooltip="topic.fullName">
                                <ui-icon :name="topic.icon" class="text-gray-700"></ui-icon>
                                <span>{{topic.name}}</span>
                            </a>
                            <span class="text-gray-700 ml-auto">{{topic.count}}</span>
                        </div>
                        <div class="py-1">
                            <div class="bg-gradient-to-r rounded-full h-1" :style="{ 'width': `${topic.count / topCount(store.analysisStore(series)) * 100}%`, '--tw-gradient-stops': `${series.color}50, ${series.color}` }"></div>
                        </div>
                    </div>
                </div>

                <div class="h-full flex flex-col items-center justify-center" v-else>
                    <div class="text-gray-600 text-sm">No topics have been found.</div>
                </div>

                <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20': isInitialized, 'bg-white': ! isInitialized}" v-if="store.analysisStore(series).isLoading">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalActions, mapLocalState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'isComparing', 'isInitialized', 'series', 'type' ]),
    },

    methods: {
        ...mapLocalActions([ 'analysisStore' ]),

        topCount(store) {
            return Math.max(...store.analysisSeries[0].values.map(s => s.count))
        }
    }
}
</script>
