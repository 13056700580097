import { defineContentSentimentAnalysisStore } from '@/stores/analysis/analyses/content-sentiment'

import determineGranularity from '@/helpers/determine-granularity'
import number from '@/helpers/number'
import { date } from '@/helpers/datetime'

import { defineStore } from 'pinia'

export const defineOverviewSentimentTimelineStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        type: 'content',
        style: 'ratio',

        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{
                ...series,
                analysisContentStore: defineContentSentimentAnalysisStore({ id: `${settings.id}ContentAnalysis` }),
                analysisRepliesStore: defineContentSentimentAnalysisStore({ id: `${settings.id}RepliesAnalysis` })
            }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = {
                ...series,
                analysisContentStore: defineContentSentimentAnalysisStore({ id: `${settings.id}ContentAnalysis${this.series.length}` }),
                analysisRepliesStore: defineContentSentimentAnalysisStore({ id: `${settings.id}RepliesAnalysis${this.series.length}` })
            })

            this.loadAnalysis(series, series.analysisContentStore(), 'content')
            this.loadAnalysis(series, series.analysisRepliesStore(), 'replies')
        },

        stopComparing(series) {
            series = this.series.find(s => s.id == series.id)

            series?.analysisContentStore().$dispose()
            series?.analysisRepliesStore().$dispose()

            this.series = this.series.filter(s => s.id != series.id)
        },

        toggleType() {
            this.type = this.type == 'content' ? 'replies' : 'content'
        },

        toggleStyle() {
            this.style = this.style == 'ratio' ? 'absolute' : 'ratio'
        },

        async load() {
            return Promise.all(this.series.flatMap(series => [
                this.loadAnalysis(series, series.analysisContentStore(), 'content'),
                this.loadAnalysis(series, series.analysisRepliesStore(), 'replies')
            ]))
        },

        loadAnalysis(series, store, type = 'content') {
            let filters = series.filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                values: [],
                meta: { type: type }
            }]

            if (filters['date']) {
                store.date = filters['date']
                store.granularity = determineGranularity(store.date)
            } else {
                store.date = { type: 'past', date: { past: 1, unit: 'years' } }
                store.granularity = 'month'
            }

            return series.data?.[type] ? store.setData(series.data[type]) : store.loadSeries()
        },

        analysisOptions(series) {
            return {
                boost: { useGPUTranslations: true },
                chart: {
                    type: 'spline',
                    resetZoomButton: { position: { x: 0, y: 0 } },
                    spacing: [2, 0, 2, 0],
                    zoomType: 'x'
                },
                credits: { enabled: false },
                exporting: {
                    chartOptions: {
                        chart: {
                            spacing: [40, 40, 40, 40]
                        }
                    },
                    buttons: {
                        contextButton: { enabled: false }
                    }
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        color: 'rgb(44, 64, 76)', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '12px', fontWeight: '500', textOverflow: 'ellipsis'
                    },
                    labelFormatter: function () { return `<span style="color:${this.color}">${this.name}</span>` },
                    margin: 20,
                    padding: 0
                },
                plotOptions: {
                    column: {
                        marker: { enabled: false },
                        fillOpacity: 1
                    },
                    series: {
                        animation: true,
                        connectNulls: true,
                        dataLabels: { enabled: false },
                        enableMouseTracking: true,
                        marker: {
                            enabled: false,
                            enabledThreshold: 0,
                            fillColor: '#fff',
                            lineColor: null,
                            lineWidth: 2,
                            radius: 4,
                            symbol: 'circle'
                        },
                        states: {
                            hover: { lineWidthPlus: 0 }
                        },
                        stacking: 'normal',
                        turboThreshold: 2500,
                        borderRadius: { radius: 8 }
                    }
                },
                series: this.analysisStore(series).analysisSeries.map(series => ({
                    type: this.style === 'ratio' && ['Positive', 'Negative', 'Neutral'].includes(series.label) ? 'column' : 'spline',
                    name: series.label,
                    color: series.color,
                    threshold: 0,
                    visible: ! ((series.label === 'Average' && this.style === 'absolute') || (series.label === 'Neutral' && this.style === 'ratio')),
                    dashStyle: 'solid',
                    data: (this.style === 'ratio' && series.label === 'Negative') ? series.values.map(v => ({ x: v.x, y: -v.y, url: v.url })) : series.values,
                    yAxis: series.label === 'Average' ? 'ratio' : 'sentiment',
                    lineWidth: 2,
                    opacity: this.style === 'ratio' && series.label !== 'Average' ? 0.75 : 1,
                    point: {
                        events: {
                            click: function () { window.open(this.options.url, '_blank') }
                        }
                    }
                })),
                title: {
                    text: ''
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    pointFormatter: function () { return `${date(this.x)}<br/>${this.series.name}<br><strong>${number(this.y)}</strong>` },
                    shadow: false,
                    style: { color: '#fff', textAlign: 'center' }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        align: 'center',
                        style: { color: '#8599a3' }
                    }
                },
                yAxis: [{
                    gridLineColor: '#e0e5e8',
                    id: 'sentiment',
                    min: this.style === 'ratio'
                            ? - (Math.max(...this.analysisStore(series).analysisSeries.filter(series => ['Positive', 'Negative', 'Neutral'].includes(series.label)).flatMap(series => series.values.map(v => v.y))) * 1.1)
                            : undefined,
                    max: this.style === 'ratio'
                            ? Math.max(...this.analysisStore(series).analysisSeries.filter(series => ['Positive', 'Negative', 'Neutral'].includes(series.label)).flatMap(series => series.values.map(v => v.y))) * 1.1
                            : undefined,
                    labels: {
                        enabled: false,
                        padding: 0,
                        style: { color: '#b8c3c9' },
                        x: 1,
                        y: 14,
                        zIndex: 1
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPixelInterval: 50,
                    title: { text: '' }
                }, {
                    gridLineWidth: 0,
                    id: 'ratio',
                    min: -1,
                    max: 1,
                    labels: {
                        enabled: false,
                        padding: 0,
                        style: { color: '#b8c3c9' },
                        x: 1,
                        y: 14,
                        zIndex: 1
                    },
                    opposite: true,
                    startOnTick: false,
                    endOnTick: false,
                    tickPixelInterval: 50,
                    title: { text: '' }
                }]
            }
        },

        isLoading(series) {
            return this.analysisStore(series).isLoading
        },

        analysisStore(series) {
            return this.type == 'replies' ? series.analysisRepliesStore() : series.analysisContentStore()
        }
    }
})

export default defineOverviewSentimentTimelineStore
