import { defineContentTopFeaturesAnalysisStore } from '@/stores/analysis/analyses/content-top-features'

import { defineStore } from 'pinia'

export const defineOverviewTopLinksStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{ ...series, analysisStore: defineContentTopFeaturesAnalysisStore({ id: `${settings.id}Analysis` }) }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = { ...series, analysisStore: defineContentTopFeaturesAnalysisStore({ id: `${settings.id}Analysis${this.series.length}` }) })

            this.loadAnalysis(series)
        },

        stopComparing(series) {
            this.series.find(s => s.id == series.id)?.analysisStore().$dispose()
            this.series = this.series.filter(s => s.id != series.id)
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadAnalysis(series)))
        },
        
        loadAnalysis(series) {
            let store = series.analysisStore()
            let filters = series.filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                meta: { type: 'url' },
                values: []
            }]

            if (filters['date']) {
                store.date = filters['date']
            } else {
                store.date = { type: 'past', date: { past: 1, unit: 'years' } }
            }

            return series.data ? store.setData(series.data) : store.loadSeries()
        }
    }
})

export default defineOverviewTopLinksStore
