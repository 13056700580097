import useMyNotificationsStore from '@/stores/me/notifications'

import api from '@/api'

export function translate(item, params = {}) {
    item.translation = { isLoading: true, text: undefined, incomplete: false }

    return api.route('monitor content translate', { id: params.id ?? item.id })
        .json(params)
        .post()
        .error(422, e => {
            if (e.json.code === 'invalid_translation_language_pair_error') {
                return useMyNotificationsStore().pushToast({
                    title: e.json.title,
                    body: e.json.message,
                    level: 'error'
                })
            }

            return e
        })
        .json(data => {
            item.translation.text = data.text
            item.translation.incomplete = data.incomplete
        })
        .finally(() => {
            item.translation.isLoading = false
        })
}

export function removeTranslation(item) {
    item.translation = undefined
}

export default translate
