import { defineStore } from 'pinia'

export const useAnalysisTypesStore = defineStore({
    id: 'analysisTypes',

    getters: {
        types: state => ([
            {
                id: 'channel-audience',
                name: 'Channel audience',
                description: 'Visualize the audience counts over a period of time.'
            },
            {
                id: 'channel-publish-times',
                name: 'Channel publish times',
                description: 'Visualize the posting activity by day or hour using a heatmap.'
            },
            {
                id: 'content-performance',
                name: 'Content performance',
                description: 'Visualize and compare prevalence, count and engagement with published content over time.'
            },
            {
                id: 'content-publish-times',
                name: 'Content publish times',
                description: 'Visualize the posting activity by day or hour using a heatmap.'
            },
            {
                id: 'content-pull-push',
                name: 'Content pull/push',
                description: 'Visualize the relationship between the prevalence of published content and received engagement over time.'
            },
            {
                id: 'content-sentiment',
                name: 'Content sentiment',
                description: 'Visualize the evolution of sentiment of published content.'
            },
            {
                id: 'content-top-channels',
                name: 'Top Channels',
                description: 'Visualize and compare break-down of published content by the channel that published it or where it was published.'
            },
            {
                id: 'content-top-iptc-topics',
                name: 'Top Topics',
                description: 'Visualize and compare break-down of published content by detected IPTC topics.'
            },
            {
                id: 'content-top-platforms',
                name: 'Top Platforms',
                description: 'Visualize and compare break-down of published content by it\'s platform.'
            },
            {
                id: 'content-keywords',
                name: 'Content keywords',
                description: 'Visualize the prevalence of keywords in published content.'
            }
        ])
    },

    actions: {
        async all() {
            return this.types
        },

        find(id) {
            return this.types.find(f => f.id == id)
        }
    }
})

export default useAnalysisTypesStore
